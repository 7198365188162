import React from 'react'
import { Badge, Group } from '@mantine/core'
import { ResponseData } from 'types'

const colors = ['red', 'pink', 'grape', 'violet', 'indigo', 'blue', 'cyan', 'green', 'lime', 'yellow', 'orange', 'teal']
function getRandomColors() {
  const fromIndex = Math.floor(Math.random() * colors.length)
  const toIndex = Math.floor(Math.random() * colors.length)
  return { from: colors[fromIndex], to: colors[toIndex] }
}

const Genres = ({ genres }: { genres: ResponseData['genres'] }) => {
  return (
    <Group mt={32}>
      {genres?.map((genre: string) => {
        return (
          <Badge key={genre} variant="gradient" gradient={getRandomColors()}>
            {genre}
          </Badge>
        )
      })}
    </Group>
  )
}

export default Genres
