import React from 'react'
import { useQuery } from 'react-query'
import { Box, Center, Container } from '@mantine/core'
import ArtistProfile from 'components/ArtistProfile'
import RadiozTitle from 'components/RadiozTitle'
import CurrentSongTitle from 'components/CurrentSongTitle'
import Player from 'components/Player'
import { ResponseData } from 'types'
import ArtistData from 'components/ArtistData'
import Genres from 'components/Genres'
import RecentTracks from 'components/RecentTracks'
import { useRadiozStyles } from 'components/styles'

const apiUrl = 'https://radioz.deno.dev'

const getNowPlaying = async () => {
  const res = await fetch(apiUrl, {
    method: 'POST'
  })
  const json = await res.json()
  return json
}

export default function App() {
  const { classes } = useRadiozStyles()
  const { data, isLoading, isError } = useQuery<ResponseData>('nowPlaying', getNowPlaying, {
    refetchInterval: 30000
  })

  return (
    <Box className={classes.appWrapper}>
      <Container>
        <Center>
          <Container>
            <RadiozTitle />
            <CurrentSongTitle
              artist={data?.artist || ''}
              track={data?.track || ''}
              isLoading={isLoading}
              isError={isError}
            />
            <Player />
          </Container>
        </Center>
        <Center>
          <ArtistData
            image={data?.image}
            albumName={data?.albumName}
            albumImage={data?.albumImage}
            albumReleaseDate={data?.albumReleaseDate}
          />
        </Center>
        <Center>
          <Genres genres={data?.genres} />
        </Center>
      </Container>
      {data?.artistProfile && <ArtistProfile artist={data.artist} profile={data.artistProfile} />}
      <RecentTracks />
    </Box>
  )
}
