import React from 'react'
import { Box, Center } from '@mantine/core'

interface CurrentSongTitle {
  artist: string
  track: string
  isLoading: boolean
  isError: boolean
}

const CurrentSongTitle = ({ artist, track, isLoading, isError }: CurrentSongTitle) => {
  return (
    <Box>
      {artist && track && (
        <Center>
          <h1 style={{ fontWeight: 400 }}>
            {artist} - {track}
          </h1>
        </Center>
      )}
      {isLoading && <h1>Loading...</h1>}
      {isError && <h1>Error</h1>}
    </Box>
  )
}

export default CurrentSongTitle
