import '@fontsource/secular-one'
import '@fontsource/monoton'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import App from './App'
import { ReactQueryDevtools } from 'react-query/devtools'
import { MantineProvider, Global } from '@mantine/core'
import { appTheme, getGlobalStyle } from 'styles/appTheme'
import { Toaster } from 'react-hot-toast'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: import.meta.env.PROD
    }
  }
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MantineProvider emotionOptions={{ key: 'radioz' }} theme={appTheme} withNormalizeCSS withGlobalStyles>
      <Global styles={() => getGlobalStyle()} />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
        <App />
      </QueryClientProvider>
    </MantineProvider>
    <Toaster position={'top-right'} toastOptions={{ style: { background: '#111', color: '#fff' } }} />
  </React.StrictMode>
)
