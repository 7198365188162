import { createStyles } from '@mantine/core'
export const useRadiozStyles = createStyles(theme => {
  return {
    appWrapper: {
      position: 'relative'
    },
    radiozTitle: {
      fontWeight: 400,
      fontSize: 80,
      color: theme.colors.red[4]
    },
    recentTracks: {
      position: 'absolute',
      width: 300,
      top: '50%',
      right: 32,
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        position: 'static',
        display: 'block',
        width: '100%',
        top: 'unset',
        right: 'unset',
        padding: '0 32px'
      }
    },
    profile: {
      position: 'absolute',
      width: 300,
      top: '50%',
      left: 32,
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        position: 'static',
        display: 'block',
        width: '100%',
        top: 'unset',
        left: 'unset',
        padding: '0 32px'
      }
    }
  }
})
