import React from 'react'
import { Badge, Box, ScrollArea, Text } from '@mantine/core'
import { useRadiozStyles } from 'components/styles'

const ArtistProfile = ({ artist, profile }: { artist: string; profile: string }) => {
  const { classes } = useRadiozStyles()
  return (
    <Box className={classes.profile}>
      <Badge mb={8}>{artist}</Badge>
      <ScrollArea
        sx={theme => ({
          height: 400,
          '@media (max-width: 576px)': {
            height: 200
          }
        })}
      >
        <Text size={'sm'}>{profile}</Text>
      </ScrollArea>
    </Box>
  )
}

export default ArtistProfile
