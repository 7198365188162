import React from 'react'
import { Box, Center } from '@mantine/core'

const Player = () => {
  return (
    <Box>
      <Center>
        <audio controls autoPlay>
          <source src="https://rbx2.hnux.com/http://149.202.22.75:9466/;?_=0.4413825566400358" type="audio/mp3" />
          Your browser does not support the
        </audio>
      </Center>
    </Box>
  )
}

export default Player
