import { MantineThemeOverride } from '@mantine/core'
import { CSSObject } from '@emotion/react'
import bg from 'styles/images/bg.jpeg'
const fontFamily = '"Secular One", sans serif'

export const getGlobalStyle = (): CSSObject | CSSObject[] => ({
  body: {
    backgroundImage: `url('${bg}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }
})
export const appTheme: MantineThemeOverride = {
  // Override any other properties from default theme
  fontFamily,
  headings: {
    fontFamily: '"Monoton", sans serif'
  },
  colorScheme: 'dark',
  primaryColor: 'red',
  primaryShade: { light: 2, dark: 6 },
  radius: {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 5,
    xl: 8
  }
  // other: {
  //   colors: {
  //     appLightBackgroundColor: 'rgb(248, 249, 252)',
  //     activeItem: '#f5feff'
  //   },
  //   elements: {
  //     appBorder: '1px solid rgba(72, 94, 144, 0.16)',
  //     sideBarWidth: 470
  //   }
  // }
}
