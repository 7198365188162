import React from 'react'
import { Box, Group, Text } from '@mantine/core'
import { ResponseData } from 'types'

interface ArtistDataProps {
  image: ResponseData['image']
  albumName: ResponseData['albumName']
  albumImage: ResponseData['albumImage']
  albumReleaseDate: ResponseData['albumReleaseDate']
}

const ArtistData = ({ image, albumName, albumImage, albumReleaseDate }: ArtistDataProps) => {
  return (
    <Group mt={32} align={'start'}>
      <Box>{image && <img src={image} width={300} alt={'main artist'} />}</Box>
      {albumName && (
        <Box>
          <Text>Album: {albumName}</Text>
          <Box>{albumImage && <img src={albumImage} width={250} alt={'album'} />}</Box>
          <Box mt={8}>{albumReleaseDate}</Box>
        </Box>
      )}x
    </Group>
  )
}

export default ArtistData
