import React from 'react'
import { Badge, Box, ScrollArea, Text } from '@mantine/core'
import { useRadiozStyles } from 'components/styles'
const RecentTracks = () => {
  const { classes } = useRadiozStyles()

  return (
    <Box className={classes.recentTracks}>
      <Badge mb={8} color={'pink'}>
        Recent Tracks
      </Badge>
      <ScrollArea style={{ height: 400 }}>
        <Text size={'sm'} id="stream_info_songhistory">
          Loading...
        </Text>
      </ScrollArea>
    </Box>
  )
}

export default RecentTracks
