import React from 'react'
import { Box, Center, Title } from '@mantine/core'
import { useRadiozStyles } from 'components/styles'

const RadiozTitle = () => {
  const { classes } = useRadiozStyles()
  return (
    <Box mt={32}>
      <Center>
        <Title order={1} className={classes.radiozTitle}>
          Radioz
        </Title>
      </Center>
    </Box>
  )
}

export default RadiozTitle
